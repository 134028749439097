'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.PAGE_BREAK_POINT_1080PX =
  exports.PAGE_BREAK_POINT_1004PX =
  exports.PAGE_BREAK_POINT_800PX =
  exports.PAGE_BREAK_POINT_640PX =
  exports.PAGE_BREAK_POINT_480PX =
    void 0;
exports.PAGE_BREAK_POINT_480PX = '480px';
exports.PAGE_BREAK_POINT_640PX = '640px';
exports.PAGE_BREAK_POINT_800PX = '800px';
exports.PAGE_BREAK_POINT_1004PX = '1004px';
exports.PAGE_BREAK_POINT_1080PX = '1080px';
